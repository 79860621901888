@use '@angular/material' as mat;
@use 'variables' as var;
@use 'sass:map';

@mixin anythingToolbar($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');

  .mat-toolbar.mat-primary {
    background-color: mat.get-color-from-palette($accent-palette, 900);
  }
}

@mixin anythingSidenav($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');
  .mat-drawer-container {
    .mat-drawer {
      background: mat.get-color-from-palette($accent-palette, 50);
    }
  }
}

@mixin anythingForms($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  // Get the primary color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  .form-group {
    border: 1px solid mat.get-color-from-palette($accent-palette, 50);

    .form-group-header {
      background-color: mat.get-color-from-palette($accent-palette, 50);
    }
  }
}

@mixin anythingObjectExport($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  div.object-export-layout {
    span.is-failed-job {
      color: var(--error);
    }

    span.is-pending-job {
      color: mat.get-color-from-palette($primary-palette, 500);
    }

    span.is-canceled-job {
      color: var(--2nd-accent);
    }

    span.is-finished-job {
      color: var(--success);
    }
  }
}
