// We add the bootstrap parts to our styles
///////////////////////////////////////////
@use '@angular/material' as mat;
@use "libs/material/src/theming/library-themes" as library-themes;
@use "libs/material/src/theming/flag-pack" as flagPack;
@use 'variables';
@use 'theming-mixins' as themes;
///////////////////////////////////////////

@tailwind base;
@tailwind utilities;

// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes(variables.$anything-theme);
@include library-themes.all-component-themes(variables.$anything-theme);

@include flagPack.flag-icon-lib(('bg','cz','de','en','hr','pl','ro','rs','sk'));
/* You can add global styles to this file, and also import other style files */

/* Custom Theming especially for this application */
@include themes.anythingToolbar(variables.$anything-theme);
@include themes.anythingSidenav(variables.$anything-theme);
@include themes.anythingForms(variables.$anything-theme);
@include themes.anythingObjectExport(variables.$anything-theme);

html, body {
  height: 100%;
}

/**
  * This is part of tailwind. Tailwind comes with the base-layer (css reset). A default reset vor svg is display:block
  * But the ngx-pdf-extended-viewer need to use display: inline, otherwise the toolbar is broken; Changing something
  * in the CSS of the pdf-viewer is difficult; hence we set the display attribute vor svg globally to inline.
 */
@layer base {
  svg {
    display: inline;
  }
}
