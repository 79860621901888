@use '@angular/material' as mat;

$mat-primary: (
  50 : #eee4ef,
  100 : #EAC5ED, //official-color
  200 : #b88dbc,
  300 : #9c5fa1,
  400 : #8A2093, //official-color
  500 : #711b79, //official-color
  600 : #691871,
  700 : #5e1466,
  800 : #54105c,
  900 : #420849,
  A100 : #ef7fff,
  A200 : #e84cff,
  A400 : #e219ff,
  A700 : #de00fe,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mat-accent: (
  50 : #f8f8f8, //official-color
  100 : #ededed, //official-color
  200 : #d4d4d4,
  300 : #d2d2d2, //official-color
  400 : #b6b6b6,
  500 : #a9a9a9, //official-color
  600 : #a2a2a2,
  700 : #989898,
  800 : #8f8f8f,
  900 : #464646, //official-color
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
  )
);

$mat-warn: (
  50 : #fce5e3,
  100 : #f7bfb8,
  200 : #f29489,
  300 : #ec6959,
  400 : #e84936,
  500 : #e42912,
  600 : #e12410,
  700 : #dd1f0d,
  800 : #d9190a,
  900 : #d10f05,
  A100 : #fffafa,
  A200 : #ffc8c7,
  A400 : #ff9794,
  A700 : #ff7e7b,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$anything-primary: mat.define-palette($mat-primary, 500, 100, 900);
$anything-accent: mat.define-palette($mat-accent, 900, 100);
$anything-warn: mat.define-palette($mat-warn);

$typography-config: mat.define-typography-config();
@include mat.typography-hierarchy($typography-config);

$anything-theme: mat.define-light-theme(
    (
      color: (
        primary: $anything-primary,
        accent: $anything-accent,
        warn: $anything-warn
      ),
      typography: $typography-config
    )
);

//Here we have some additional colors based on the Schwarz CD
:root {
  --success: #43B970;
  --error: #E42912;
  --2nd-accent: #FF9800;
}



